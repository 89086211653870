import { createModel } from '@rematch/core';
import { getDateDisplayKeyOrString } from '@utilities/dates';
import { PROMO_CODE_GROUP } from '@models/Codes';
import type { RootModel } from './models';
import type { RootState } from './store';
import { DAYS_PER_MONTH, DAYS_PER_YEAR, HOUR_PER_DAY, MIN_PER_HOUR, MS_PER_SEC, SEC_PER_MIN, TWO_WEEKS_IN_DAYS } from '@constants/Numbers';
import type { OrderCart, OrderCode, OrderPlan } from '@models/store';
import { requestCart, requestPromoCode } from '../@v2';

type OrderState = {
    cart: OrderCart
    code: OrderCode
    currentTrialOffer: number
    plan: OrderPlan
    isOrderUpdating: boolean
    address?: {
        line1?: string;
        line2?: string;
        city?: string;
        country?: string;
        metadata?: {
            cpf?: string;
            name?: string;
        };
        postalCode?: number;
        state?: string;
    }
}

export const order = createModel<RootModel>()({
    state: {} as OrderState,
    reducers: {
        clearAddress: (state) => ({
            ...state,
            address: {}
        }),
        setAddress: (state, payload) => ({
            ...state,
            address: {
                ...state.address,
                ...payload
            }
        }),
        setOrderState: (state, payload) => ({
            ...state,
            ...payload
        }),
        setCart: (state, payload) => ({
            ...state,
            cart: {
                currency: payload.currency,
                discount: payload.discount,
                errors: payload.errors,
                intro_offer: payload.intro_offer,
                needs_postal_code: payload.needs_postal_code,
                payment_required: payload.payment_required,
                plan: payload.plan,
                pretax_total: payload.pretax_total,
                renewal_pretax_total: payload.renewal_pretax_total,
                renewal_tax: payload.renewal_tax,
                renewal_total: payload.renewal_total,
                subtotal: payload.subtotal,
                supported_platforms: payload.supported_platforms,
                tax: payload.tax,
                total: payload.total,
                trial_end_date: payload.trial_end_date,
                taxes: payload.taxes,
            }
        }),
        setCode: (state, payload) => {
            if (payload.grants || payload.rules) {
                const codeObject: OrderCode = { code: payload.code, group: payload?.group };
                payload?.grants?.forEach((grant) => {
                    if (grant.name === 'discount_grant') codeObject.discount = grant.variables.amount;
                    if (grant.name === 'trial_days_grant') codeObject.trial_days = grant.variables.trial_days;
                    if (grant.name === 'payment_not_required_grant') codeObject.payment_required = false;
                    if (grant.name === 'trial_end_date_grant') codeObject.code_end_date = getDateDisplayKeyOrString(grant.variables.end_date);
                    if (grant.name === 'one_time_payment_grant') codeObject.one_time_payment = grant.variables;
                });
                payload?.rules?.forEach((rule) => {
                    if (rule.name === 'valid_until_rule') codeObject.valid_until = getDateDisplayKeyOrString(rule.variables.end_date);
                    if (rule.name === 'code_limit_rule') codeObject.code_limit = rule.variables.limit;
                    if (rule.name === 'had_previous_trial_rule') codeObject.had_previous_trial = true; // if rule is present, code is restricted to first trial only
                });
                return {
                    ...state,
                    code: codeObject
                };
            }
            return { ...state, code: payload };
        },
        setCurrentTrialOffer: (state, payload) => ({
            ...state,
            currentTrialOffer: payload
        }),
        setIsOrderUpdating: (state, payload) => ({
            ...state,
            isOrderUpdating: payload
        }),
        setPlan: (state, payload) => ({
            ...state,
            plan: payload
        })
    },
    effects: (dispatch) => ({
        // this effect should not be called if cart contains errors
        updateOrderState(apiCartData) {
            dispatch.order.setCart(apiCartData);

            if (apiCartData?.product) dispatch.order.setPlan(apiCartData.product);

            if (apiCartData?.promo_code) dispatch.order.setCode(apiCartData.promo_code);
            else dispatch.order.setCode({});

            dispatch.order.setIsOrderUpdating(false);
        },
        // Using fetch, cannot use api.ts or network.ts utilities because they import the store
        async updateCodeState(code, state, doNotFetchCart = false) {
            let isErrorSet = false; // avoid showing same error alert twice

            const checkPromoCode = async () => {
                const response = await requestPromoCode({ promoCode: code });
                const codeJSON = JSON.parse(await response.text());

                if (codeJSON?.error) {
                    dispatch.order.setIsOrderUpdating(false);
                    if (!isErrorSet) dispatch.session.setErrorToDisplay(codeJSON.error);
                } else {
                    // include code, group in every code object
                    const codeObject: OrderCode = { code, group: codeJSON?.group };
                    codeJSON?.grants?.forEach((grant) => {
                        if (grant.name === 'discount_grant') codeObject.discount = grant.variables.amount;
                        if (grant.name === 'trial_days_grant') codeObject.trial_days = grant.variables.trial_days;
                        if (grant.name === 'payment_not_required_grant') codeObject.payment_required = false;
                        if (grant.name === 'trial_end_date_grant') codeObject.code_end_date = getDateDisplayKeyOrString(grant.variables.end_date);
                        if (grant.name === 'one_time_payment_grant') codeObject.one_time_payment = grant.variables;
                    });
                    codeJSON?.rules?.forEach((rule) => {
                        if (rule.name === 'valid_until_rule') codeObject.valid_until = getDateDisplayKeyOrString(rule.variables.end_date);
                        if (rule.name === 'code_limit_rule') codeObject.code_limit = rule.variables.limit;
                        if (rule.name === 'had_previous_trial_rule') codeObject.had_previous_trial = true; // if rule is present, code is restricted to first trial only
                    });
                    dispatch.order.setCode(codeObject);
                    dispatch.order.setIsOrderUpdating(false);
                }
            };

            if (state.user?.id) {
                const priceId = state.order?.plan?.price.id ?? state.session?.defaultApiPlan?.price.id;

                // do NOT fetch cart if told not to
                // when syncing react-query to redux...
                if (!doNotFetchCart) {
                    const response = await requestCart({
                        requestInit: {
                            method: 'POST',
                            body: JSON.stringify({ price_id: priceId, promo_code: code }),
                        }
                    });
                    const cart = JSON.parse(await response.text());
                    
                    if (cart?.errors?.length) {
                        isErrorSet = true;
                        dispatch.session.setErrorToDisplay(cart.errors[0].error);
                        checkPromoCode();
                    } else dispatch.order.updateOrderState(cart);
                }
            } else {
                checkPromoCode();
            }
        }
    })
});

const NUM_TRIAL_MONTHS = 3;

export const selectAddress = (state: RootState) => state.order?.address;
export const selectCart = (state: RootState) => state.order?.cart;
export const selectDiscount = (state: RootState) => {
    if (state.order?.code?.discount) return state.order?.code?.discount;
    const discountGrant = state.order?.code?.grants?.find((grant) => grant.name === 'discount_grant');
    return discountGrant ? discountGrant.variables.amount : null;
};
export const selectIsOrderUpdating = (state: RootState) => state.order?.isOrderUpdating;
export const selectPlan = (state: RootState) => state.order?.plan;
export const selectPlanPriceId = (state: RootState) => state.order?.plan?.price.id;
export const selectPlanPriceAmount = (state: RootState) => state.order?.plan?.price.amount;
export const selectPlanPriceCurrency = (state: RootState) => state.order?.plan?.price.currency ?? 'USD';
export const selectPlanPriceRegion = (state: RootState) => state.order?.plan?.price?.region;
export const selectPromoCode = (state: RootState) => state.order?.code?.code;
export const selectSubtotal = (state: RootState) => state.order?.cart?.subtotal ?? state.order?.plan?.price.amount;
export const selectTotal = (state: RootState) => {
    let total = state.order?.cart?.total ?? state.order?.plan?.price.amount;

    let oneTimePaymentGrant;
    if (state.order?.code?.one_time_payment) oneTimePaymentGrant = state.order.code.one_time_payment;
    else oneTimePaymentGrant = state.order?.code?.grants?.find((grant) => grant.name === 'one_time_payment_grant');

    if (oneTimePaymentGrant) total = oneTimePaymentGrant?.amount ?? oneTimePaymentGrant?.variables?.amount;

    return total;
};
export const selectCodeGroup = (state: RootState) => {
    if (state.order?.code?.group) return state.order?.code?.group;

    if (state.order?.code?.payment_required === false
        && (state.order?.code?.trial_days === DAYS_PER_MONTH * NUM_TRIAL_MONTHS || state.order?.code?.trial_days === DAYS_PER_YEAR)) {
        return PROMO_CODE_GROUP.GIFT_CARD;
    }

    return null;
};
export const selectPaymentRequired = (state: RootState) => {
    if (state.order?.cart?.hasOwnProperty('payment_required')) return state.order?.cart?.payment_required;
    if (state.order?.code?.hasOwnProperty('payment_required')) return state.order?.code?.payment_required;
    return true;
};
export const selectTrialDays = (state: RootState) => {
    const calculateTrialDays = (dateString: string) => {
        const MS_PER_DAY = MS_PER_SEC * SEC_PER_MIN * MIN_PER_HOUR * HOUR_PER_DAY;

        const today = new Date;
        const trialEnd = new Date(dateString);

        const utcToday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const utcTrialEnd = Date.UTC(trialEnd.getFullYear(), trialEnd.getMonth(), trialEnd.getDate());

        return Math.floor((utcTrialEnd - utcToday) / MS_PER_DAY);
    };

    const cartTrialDays = state.order?.cart?.trial_end_date ? calculateTrialDays(state.order?.cart?.trial_end_date) : null;
    if (cartTrialDays != null && cartTrialDays !== TWO_WEEKS_IN_DAYS && cartTrialDays !== TWO_WEEKS_IN_DAYS) return cartTrialDays;

    if (state.order?.code?.trial_days) return state.order?.code?.trial_days;

    if (cartTrialDays) return cartTrialDays;

    // This line will run with the default plan we choose from apiGetPlans (annual plan)
    if (state.order?.plan?.trial_days) return state.order?.plan?.trial_days;

    // company default, unauth'd users may use
    return state.order?.currentTrialOffer ?? TWO_WEEKS_IN_DAYS;
};

export const selectCodeEndDate = (state: RootState) => {
    // check for end date under code_end_date and grants (trial_end_date_grant)
    if (state.order?.code?.code_end_date) return state.order.code.code_end_date;

    const trialEndDateGrant = state.order?.code?.grants?.find((grant) => grant.name === 'trial_end_date_grant');

    if (trialEndDateGrant) return getDateDisplayKeyOrString(trialEndDateGrant.variables?.end_date);
    return null;
};

export const selectOneTimePayment = (state: RootState): OrderCode['one_time_payment'] => {
    // check for one time payment under one_time_payment and grants (one_time_payment_grant)
    if (state.order?.code?.one_time_payment) return state.order.code.one_time_payment;
    const oneTimePaymentGrant = state.order?.code?.grants?.find((grant) => grant.name === 'one_time_payment_grant');
    return oneTimePaymentGrant ? oneTimePaymentGrant.variables : null;
};

export const selectHadPreviousTrial = (state: RootState): OrderCode['had_previous_trial'] => {
    // check for restrict to first trial under had_previous_trial and rules (had_previous_trial_rule)
    if (state.order?.code?.had_previous_trial) return state.order?.code?.had_previous_trial;
    const hadPreviousTrialRule = state.order?.code?.rules?.find((rule) => rule.name === 'had_previous_trial_rule');
    return !!hadPreviousTrialRule;
};