import { GoogleTagManager as GTM } from '@next/third-parties/google';
import { useSelector } from 'react-redux';
import { selectUserConsent } from '@store/userModel';
import { USER_CONSENT } from '@models/store';

const GTMProvider = () => {
    const userConsent = useSelector(selectUserConsent);

    if (userConsent?.includes(USER_CONSENT.TRACK_ANALYTICS)) {
        return <GTM gtmId={process.env.ACCESS_GOOGLE_TAG_MANAGER_ID} />;
    }

    return null;
};

export default GTMProvider;