import ApplePayButton from '@components/PaymentProcessors/ApplePayButton';
import GooglePayButton from '@components/PaymentProcessors/GooglePayButton';
import { type HTMLAttributes, useContext } from 'react';
import { PAYPAL_INTENTS } from '@models/PaymentProcessors';
import PaypalProcessorDropdown from '@components/PaypalProcessorDropdown';
import StripeCardFormDropdown from '@components/StripeCardFormDropdown';
import styles from '../../../../styles/modules/products/pageCheckout.module.scss';
import { SyncReactQueryWithReduxWrapper } from '../SyncReactQueryWithReduxWrapper/SyncReactQueryWithReduxWrapper';
import { useHallowStripe } from '@utilities/hooks/useHallowStripe';
import { useIntl } from 'react-intl';
import { useCart, UsePayments, usePromoCode } from '../../../utilities';
import { OnboardingContext } from '@providers/OnboardingProvider/OnboardingContext';

export type CartPaymentsProps = HTMLAttributes<HTMLDivElement> & {
    defaultContinueText?: boolean;
    disabled?: boolean;
    overrideTotal?: number
};

export const CartPayments = ({ defaultContinueText = false, disabled, overrideTotal, ...props }: CartPaymentsProps) => {
    const intl = useIntl();

    const { getCartCurrency, getCartPromoCode, getCartTotal } = useCart();
    const { getPromoCodeAmountIntl, getPromoCodeTrialLengthIntl } = usePromoCode({ promoCode: getCartPromoCode() });
    const stripe = useHallowStripe(overrideTotal ?? getCartTotal(), 'onboarding');
    const { isPaypalOrStripeOpen, setIsStripeCardFormOpen, showPaypal, showStripe, togglePaypal, toggleStripe } = UsePayments();

    const { webPaypalRemovalVariant } = useContext(OnboardingContext);

    return (
        <SyncReactQueryWithReduxWrapper>
            <div {...props}>
                {webPaypalRemovalVariant !== 'treatment' && showPaypal && getCartCurrency() === 'USD'
                    && <PaypalProcessorDropdown
                        additionalContainerClass={styles.paymentProcessorButton}
                        additionalDropdownState={togglePaypal}
                        disabled={disabled}
                        flow={'onboarding'}
                        intent={PAYPAL_INTENTS.SUBSCRIPTION}
                        submitButtonText=""
                        total={overrideTotal ?? getCartTotal()}
                    />
                }
                {showStripe && (
                    <StripeCardFormDropdown
                        additionalContainerClass={styles.paymentProcessorButton}
                        additionalDropdownState={toggleStripe}
                        additionalLoadingState={setIsStripeCardFormOpen}
                        disabled={disabled}
                        submitButtonText={defaultContinueText ? intl.formatMessage({ id: 'onboarding_button_continue' }) : intl.formatMessage({ id: 'dynamic_words_redeem_for' }, { 0: getPromoCodeTrialLengthIntl(), 1: getPromoCodeAmountIntl() }) }
                        stripe={stripe}
                    />
                )}
                {!isPaypalOrStripeOpen && <ApplePayButton disabled={disabled} stripe={stripe} containerClass={styles.paymentProcessorButton} />}
                {!isPaypalOrStripeOpen && <GooglePayButton disabled={disabled} stripe={stripe} containerClass={styles.paymentProcessorButton} />}
            </div>
        </SyncReactQueryWithReduxWrapper>
    );
};